.animated-check {
  height: 10em;
  width: 10em;
}

.animated-check path {
  fill: none;
  stroke: #7ac142;
  stroke-width: 4;
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  animation: draw 0.3s linear forwards 0.2s;
  stroke-linecap: round;
  stroke-linejoin: round;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
