@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(-25px);
  }
  30% {
    transform: translateX(20px);
  }
  45% {
    transform: translateX(-15px);
  }
  60% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

@keyframes rubberBand {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes jello {
  0%,
  11.1%,
  100% {
    transform: none;
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes squish {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2, 0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

@keyframes flip {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
}

.parent-animation-on-hover:hover .flip-on-hover {
  animation: flip 0.5s;
}

.parent-animation-on-hover:hover .shrink-on-hover {
  animation: shrink 0.5s;
}

.parent-animation-on-hover:hover .grow-on-hover {
  animation: grow 0.5s;
}

.parent-animation-on-hover:hover .fade-in-on-hover {
  animation: fadeIn 0.5s;
}

.parent-animation-on-hover:hover .squish-on-hover {
  animation: squish 0.5s;
}

.parent-animation-on-hover:hover .jello-on-hover {
  animation: jello 1s;
}

.parent-animation-on-hover:hover .rubberBand-on-hover {
  animation: rubberBand 1s;
}

.parent-animation-on-hover:hover .flash-on-hover {
  animation: flash 1s;
}

.parent-animation-on-hover:hover .swing-on-hover {
  animation: swing 1s ease-in-out;
}

.parent-animation-on-hover:hover .pulse-on-hover {
  animation: pulse 0.5s;
}

.parent-animation-on-hover:hover .wobble-on-hover {
  animation: wobble 1s;
}

.parent-animation-on-hover:hover .bounce-on-hover {
  animation: bounce 1s;
}

.parent-animation-on-hover:hover .shake-on-hover {
  animation: shake 0.5s forwards;
}
