@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* --color-primary: #35c7b4; */
  --color-primary: #4ca35c;
  --color-hoverPrimary: #30773d;
  --color-primaryRGBA: rgba(137, 191, 66, 0.5);
  /* --color-hoverPrimary: #239f8f; */
  --color-secondary: #eff172;
  --color-secondaryTransparent: #eff172d3;
  --color-text: #00060c;
  --color-hoverSecondary: #e8ea80;
  --color-success: #8bde74;
  --color-danger: #ef233c;
  --color-warning: #efaf4e;
  --color-info: #6eaaff;
  --color-sidebar: #ffffff;
  --color-mainSection: #efefef;
  --color-inputBg: #f3f4f6;
  --color-tableHead: #f7f7f7;
  --color-border: #f3f4f6;
  --color-mute: #6b7280;
}
html.dark {
  /* --color-primary: #35c7b4; */
  --color-primary: #4ca35c;
  --color-hoverPrimary: #30773d;
  --color-primaryRGBA: rgba(137, 191, 66, 0.5);
  /* --color-hoverPrimary: #239f8f; */
  --color-secondary: #eff172;
  --color-secondaryTransparent: #eff172d3;
  --color-text: #ffffff;
  --color-hoverSecondary: #e8ea80;
  --color-success: #8bde74;
  --color-danger: #ef233c;
  --color-warning: #efaf4e;
  --color-info: #6eaaff;
  --color-sidebar: #27282c;
  --color-mainSection: #242529;
  --color-inputBg: #2b2c31;
  --color-tableHead: #343438;
  --color-border: #3f3f46;
  --color-mute: #d1d5db;
}

body,
html,
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-sidebar);
  color: var(--color-text);
}

/* html.dark 
body {
  background-color: #242529;
  color: #fff;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.transition300 {
  @apply transition-all duration-300;
}

/* REMOVE THE BUTTONS OF NUMBER INPUT */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* margin: 0; */
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Global scrollbar styles */
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--color-mute);
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-hoverPrimary);
}
/*---------------------- day picker reset styles  ----------------------------*/
.rdp-chevron {
  @apply fill-primary;
}

.rdp-day.rdp-today .rdp-day_button {
  @apply text-primary;
}
.rdp-selected .rdp-day_button {
  @apply text-text border-primary;
}

.quill .ql-container.ql-snow {
  @apply border-none min-h-[250px];
}

.quill .ql-toolbar.ql-snow {
  @apply border-border;
}

.quill .ql-container.ql-snow .ql-editor.ql-blank::before {
  @apply text-mute;
}

@keyframes indexPuls {
  50% {
    opacity: 0.5;
  }
}
.animate-indexPuls {
  animation: indexPuls 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes uploadFill {
  0% {
    clip-path: polygon(50% 100%, 50% 100%, 50% 100%);
  }
  50% {
    clip-path: polygon(50% 100%, 50% 0%, 0% 100%);
  }
  100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

.animate-uploadFill {
  animation: uploadFill 3s ease-in-out forwards;
}
